let drainsdata = [
  {
    Code: "50PDSS035",
    ShortCode: "035",
    Type: "Primer",
    Dia: "50",
    Material: "Ps. Çelik (304)",
    Connection: "Kelepçeli",
    AppliedTo: "Teras & Oluk",
    Description: "Teras ve küçük çatı alanları",
  },
  {
    Code: "50PDSS036",
    ShortCode: "036",
    Type: "Primer",
    Dia: "50",
    Material: "Ps. Çelik (304)",
    Connection: "Vidalı",
    AppliedTo: "Oluk",
  },
  {
    Code: "50PDSS037",
    ShortCode: "037",
    Type: "Primer",
    Dia: "50",
    Material: "Ps. Çelik (304)",
    Connection: "Kelepçeli",
    AppliedTo: "Oluk",
    Description: "140mm genişliğiyle dar oluklarda kullanıma uygundur",
  },
  {
    Code: "75PDSS038",
    ShortCode: "038",
    Type: "Primer",
    Dia: "75",
    Material: "Ps. Çelik (304)",
    Connection: "Vidalı",
    AppliedTo: "Oluk",
    Description: "190mm genişliğiyle dar oluklarda kullanıma uygundur",
  },
  {
    Code: "75PDAL023",
    ShortCode: "023",
    Type: "Primer",
    Dia: "75",
    Material: "Alüminyum",
    Connection: "Kaynaklı",
    AppliedTo: "Alüminyum Oluk",
    MinFlow: "1.5 lt/sn",
    MaxFlow: "25 lt/sn",
    Description: "1,5 - 25 lt/sn yağmur yükündeki Alüminyum oluklar",
  },
  {
    Code: "75ODAL024",
    ShortCode: "024",
    Type: "Taşkan",
    Dia: "75",
    Material: "Alüminyum",
    Connection: "Kaynaklı",
    AppliedTo: "Alüminyum Oluk",
    MinFlow: "1.5 lt/sn",
    MaxFlow: "25 lt/sn",
    Description: "Alüminyum oluklar",
  },
  {
    Code: "75PDAL017",
    ShortCode: "017",
    Type: "Primer",
    Dia: "75",
    Material: "Alüminyum",
    Connection: "Vidalı",
    AppliedTo: "Alüminyum Oluk",
    MinFlow: "1.5 lt/sn",
    MaxFlow: "25 lt/sn",
    Description: "Alüminyum oluklar",
  },
  {
    Code: "75ODAL018",
    ShortCode: "018",
    Type: "Taşkan",
    Dia: "75",
    Material: "Alüminyum",
    Connection: "Vidalı",
    AppliedTo: "Alüminyum Oluk",
    MinFlow: "1.5 lt/sn",
    MaxFlow: "25 lt/sn",
    Description: "Alüminyum oluklar",
  },
  {
    Code: "75PDSS025",
    ShortCode: "025",
    Type: "Primer",
    Dia: "75",
    Material: "Ps. Çelik (304)",
    Connection: "Kaynaklı",
    AppliedTo: "Oluk",
    MinFlow: "1.5 lt/sn",
    MaxFlow: "25 lt/sn",
    Description: "304 çelik oluklar",
  },
  {
    Code: "75PDSS025 - 316",
    ShortCode: "025 - 316",
    Type: "Primer",
    Dia: "75",
    Material: "Ps. Çelik (316)",
    Connection: "Kaynaklı",
    AppliedTo: "Oluk",
    MinFlow: "1.5 lt/sn",
    MaxFlow: "25 lt/sn",
    Description: "316 çelik oluklar",
  },
  {
    Code: "75ODSS026",
    ShortCode: "026",
    Type: "Taşkan",
    Dia: "75",
    Material: "Ps. Çelik (304)",
    Connection: "Kaynaklı",
    AppliedTo: "Oluk",
    MinFlow: "1.5 lt/sn",
    MaxFlow: "25 lt/sn",
    Description: "304 çelik oluklar",
  },
  {
    Code: "75ODSS026 - 316",
    ShortCode: "026 - 316",
    Type: "Taşkan",
    Dia: "75",
    Material: "Ps. Çelik (316)",
    Connection: "Kaynaklı",
    AppliedTo: "Oluk",
    MinFlow: "1.5 lt/sn",
    MaxFlow: "25 lt/sn",
    Description: "316 çelik oluklar",
  },
  {
    Code: "75PDSS021",
    ShortCode: "021",
    Type: "Primer",
    Dia: "75",
    Material: "Ps. Çelik (304)",
    Connection: "Vidalı",
    AppliedTo: "Oluk",
    MinFlow: "1.5 lt/sn",
    MaxFlow: "25 lt/sn",
    Description: "304 çelik oluklar",
  },
  {
    Code: "75PDSS021 - 316",
    ShortCode: "021 - 316",
    Type: "Primer",
    Dia: "75",
    Material: "Ps. Çelik (316)",
    Connection: "Vidalı",
    AppliedTo: "Oluk",
    MinFlow: "1.5 lt/sn",
    MaxFlow: "25 lt/sn",
    Description: "316 çelik oluklar",
  },
  {
    Code: "75ODSS022",
    ShortCode: "022",
    Type: "Taşkan",
    Dia: "75",
    Material: "Ps. Çelik (304)",
    Connection: "Vidalı",
    AppliedTo: "Oluk",
    MinFlow: "1.5 lt/sn",
    MaxFlow: "25 lt/sn",
    Description: "304 çelik oluklar",
  },
  {
    Code: "75ODSS022 - 316",
    ShortCode: "022 - 316",
    Type: "Taşkan",
    Dia: "75",
    Material: "Ps. Çelik (316)",
    Connection: "Vidalı",
    AppliedTo: "Oluk",
    MinFlow: "1.5 lt/sn",
    MaxFlow: "25 lt/sn",
    Description: "316 çelik oluklar",
  },
  {
    Code: "75PDSS029",
    ShortCode: "029",
    Type: "Primer",
    Dia: "75",
    Material: "Ps. Çelik (304)",
    Connection: "Kelepçeli",
    AppliedTo: "Teras & Oluk",
    MinFlow: "1.5 lt/sn",
    MaxFlow: "25 lt/sn",
    Description:
      "PVC dışındaki membran malzemelerle su yalıtımı yapılmış oluk ve teras çatılar",
  },
  {
    Code: "75PDSS029 - 316",
    ShortCode: "029 - 316",
    Type: "Primer",
    Dia: "75",
    Material: "Ps. Çelik (316)",
    Connection: "Kelepçeli",
    AppliedTo: "Teras & Oluk",
    MinFlow: "1.5 lt/sn",
    MaxFlow: "25 lt/sn",
    Description:
      "PVC dışındaki membran malzemelerle su yalıtımı yapılmış oluk ve teras çatılar",
  },
  {
    Code: "75ODSS030",
    ShortCode: "030",
    Type: "Taşkan",
    Dia: "75",
    Material: "Ps. Çelik (304)",
    Connection: "Kelepçeli",
    AppliedTo: "Teras & Oluk",
    MinFlow: "1.5 lt/sn",
    MaxFlow: "25 lt/sn",
    Description:
      "PVC dışındaki membran malzemelerle su yalıtımı yapılmış oluk ve teras çatılar",
  },
  {
    Code: "75ODSS030 - 316",
    ShortCode: "030 - 316",
    Type: "Taşkan",
    Dia: "75",
    Material: "Ps. Çelik (316)",
    Connection: "Kelepçeli",
    AppliedTo: "Teras & Oluk",
    MinFlow: "1.5 lt/sn",
    MaxFlow: "25 lt/sn",
    Description:
      "PVC dışındaki membran malzemelerle su yalıtımı yapılmış oluk ve teras çatılar",
  },
  {
    Code: "75PDMS019",
    ShortCode: "019",
    Type: "Primer",
    Dia: "75",
    Material: "Galvanize Çelik",
    Connection: "Vidalı",
    AppliedTo: "Oluk",
    MinFlow: "1.5 lt/sn",
    MaxFlow: "25 lt/sn",
    Description: "Galvaniz Oluklar",
  },
  {
    Code: "75ODMS020",
    ShortCode: "020",
    Type: "Taşkan",
    Dia: "75",
    Material: "Galvanize Çelik",
    Connection: "Vidalı",
    AppliedTo: "Oluk",
    MinFlow: "1.5 lt/sn",
    MaxFlow: "25 lt/sn",
    Description: "Galvaniz Oluklar",
  },
  {
    Code: "75PDMS015",
    ShortCode: "015",
    Type: "Primer",
    Dia: "75",
    Material: "Galvanize Çelik",
    Connection: "Bitümlü",
    AppliedTo: "Teras & Oluk",
    MinFlow: "1.5 lt/sn",
    MaxFlow: "25 lt/sn",
    Description: "Asfalt / Bitüm kaplamalı çatılar",
  },
  {
    Code: "75ODMS016",
    ShortCode: "016",
    Type: "Taşkan",
    Dia: "75",
    Material: "Galvanize Çelik",
    Connection: "Bitümlü",
    AppliedTo: "Teras & Oluk",
    MinFlow: "1.5 lt/sn",
    MaxFlow: "25 lt/sn",
    Description: "Asfalt / Bitüm kaplamalı çatılar",
  },
  {
    Code: "75PDMS027",
    ShortCode: "027",
    Type: "Primer",
    Dia: "75",
    Material: "Galvanize Çelik",
    Connection: "PVC",
    AppliedTo: "Teras & Oluk",
    MinFlow: "1.5 lt/sn",
    MaxFlow: "25 lt/sn",
    Description: "PVC membran su yalıtımlı çatı ve oluklar",
  },
  {
    Code: "75ODMS028",
    ShortCode: "028",
    Type: "Taşkan",
    Dia: "75",
    Material: "Galvanize Çelik",
    Connection: "PVC",
    AppliedTo: "Teras & Oluk",
    MinFlow: "1.5 lt/sn",
    MaxFlow: "25 lt/sn",
    Description: "PVC membran su yalıtımlı çatı ve oluklar",
  },
  {
    Code: "75CDSS032",
    ShortCode: "032",
    Type: "Primer",
    Dia: "75",
    Material: "Ps. Çelik (304)",
    Connection: "Kelepçeli",
    AppliedTo: "Teras & Dış mekan",
    MinFlow: "1.5 lt/sn",
    MaxFlow: "25 lt/sn",
    Description: "Otopark gibi yüksek yük kapasitesi gereken yerlerde",
  },
  {
    Code: "125PDAL007",
    ShortCode: "007",
    Type: "Primer",
    Dia: "125",
    Material: "Alüminyum",
    Connection: "Kaynaklı",
    AppliedTo: "Alüminyum Oluk",
    MinFlow: "10 lt/sn",
    MaxFlow: "100 lt/sn",
    Description: "Alüminyum oluklar",
  },
  {
    Code: "125ODAL008",
    ShortCode: "008",
    Type: "Taşkan",
    Dia: "125",
    Material: "Alüminyum",
    Connection: "Kaynaklı",
    AppliedTo: "Alüminyum Oluk",
    MinFlow: "10 lt/sn",
    MaxFlow: "100 lt/sn",
    Description: "Alüminyum oluklar",
  },
  {
    Code: "125PDAL003",
    ShortCode: "003",
    Type: "Primer",
    Dia: "125",
    Material: "Alüminyum",
    Connection: "Vidalı",
    AppliedTo: "Alüminyum Oluk",
    MinFlow: "10 lt/sn",
    MaxFlow: "100 lt/sn",
    Description: "Alüminyum oluklar",
  },
  {
    Code: "125ODAL004",
    ShortCode: "004",
    Type: "Taşkan",
    Dia: "125",
    Material: "Alüminyum",
    Connection: "Vidalı",
    AppliedTo: "Alüminyum Oluk",
    MinFlow: "10 lt/sn",
    MaxFlow: "100 lt/sn",
    Description: "Alüminyum oluklar",
  },
  {
    Code: "125PDSS009",
    ShortCode: "009",
    Type: "Primer",
    Dia: "125",
    Material: "Ps. Çelik (304)",
    Connection: "Kaynaklı",
    AppliedTo: "Oluk",
    MinFlow: "10 lt/sn",
    MaxFlow: "100 lt/sn",
    Description: "304 çelik oluklar",
  },
  {
    Code: "125PDSS009 - 316",
    ShortCode: "009 - 316",
    Type: "Primer",
    Dia: "125",
    Material: "Ps. Çelik (316)",
    Connection: "Kaynaklı",
    AppliedTo: "Oluk",
    MinFlow: "10 lt/sn",
    MaxFlow: "100 lt/sn",
    Description: "316 çelik oluklar",
  },
  {
    Code: "125ODSS010",
    ShortCode: "010",
    Type: "Taşkan",
    Dia: "125",
    Material: "Ps. Çelik (304)",
    Connection: "Kaynaklı",
    AppliedTo: "Oluk",
    MinFlow: "10 lt/sn",
    MaxFlow: "100 lt/sn",
    Description: "304 çelik oluklar",
  },
  {
    Code: "125ODSS010 - 316",
    ShortCode: "010 - 316",
    Type: "Taşkan",
    Dia: "125",
    Material: "Ps. Çelik (316)",
    Connection: "Kaynaklı",
    AppliedTo: "Oluk",
    MinFlow: "10 lt/sn",
    MaxFlow: "100 lt/sn",
    Description: "316 çelik oluklar",
  },
  {
    Code: "125PDSS005",
    ShortCode: "005",
    Type: "Primer",
    Dia: "125",
    Material: "Ps. Çelik (304)",
    Connection: "Vidalı",
    AppliedTo: "Oluk",
    MinFlow: "10 lt/sn",
    MaxFlow: "100 lt/sn",
    Description: "304 çelik oluklar",
  },
  {
    Code: "125PDSS005 - 316",
    ShortCode: "005 - 316",
    Type: "Primer",
    Dia: "125",
    Material: "Ps. Çelik (316)",
    Connection: "Vidalı",
    AppliedTo: "Oluk",
    MinFlow: "10 lt/sn",
    MaxFlow: "100 lt/sn",
    Description: "316 çelik oluklar",
  },
  {
    Code: "125ODSS006",
    ShortCode: "006",
    Type: "Taşkan",
    Dia: "125",
    Material: "Ps. Çelik (304)",
    Connection: "Vidalı",
    AppliedTo: "Oluk",
    MinFlow: "10 lt/sn",
    MaxFlow: "100 lt/sn",
    Description: "304 çelik oluklar",
  },
  {
    Code: "125ODSS006 - 316",
    ShortCode: "006 - 316",
    Type: "Taşkan",
    Dia: "125",
    Material: "Ps. Çelik (316)",
    Connection: "Vidalı",
    AppliedTo: "Oluk",
    MinFlow: "10 lt/sn",
    MaxFlow: "100 lt/sn",
    Description: "316 çelik oluklar",
  },
  {
    Code: "125PDSS013",
    ShortCode: "013",
    Type: "Primer",
    Dia: "125",
    Material: "Ps. Çelik (304)",
    Connection: "Kelepçeli",
    AppliedTo: "Teras & Oluk",
    MinFlow: "10 lt/sn",
    MaxFlow: "100 lt/sn",
    Description:
      "PVC dışındaki membran malzemelerle su yalıtımı yapılmış oluk ve teras çatılar",
  },
  {
    Code: "125PDSS013 - 316",
    ShortCode: "013 - 316",
    Type: "Primer",
    Dia: "125",
    Material: "Ps. Çelik (316)",
    Connection: "Kelepçeli",
    AppliedTo: "Teras & Oluk",
    MinFlow: "10 lt/sn",
    MaxFlow: "100 lt/sn",
    Description:
      "PVC dışındaki membran malzemelerle su yalıtımı yapılmış oluk ve teras çatılar",
  },
  {
    Code: "125ODSS014",
    ShortCode: "014",
    Type: "Taşkan",
    Dia: "125",
    Material: "Ps. Çelik (304)",
    Connection: "Kelepçeli",
    AppliedTo: "Teras & Oluk",
    MinFlow: "10 lt/sn",
    MaxFlow: "100 lt/sn",
    Description:
      "PVC dışındaki membran malzemelerle su yalıtımı yapılmış oluk ve teras çatılar",
  },
  {
    Code: "125ODSS014 - 316",
    ShortCode: "014 - 316",
    Type: "Taşkan",
    Dia: "125",
    Material: "Ps. Çelik (316)",
    Connection: "Kelepçeli",
    AppliedTo: "Teras & Oluk",
    MinFlow: "10 lt/sn",
    MaxFlow: "100 lt/sn",
    Description:
      "PVC dışındaki membran malzemelerle su yalıtımı yapılmış oluk ve teras çatılar",
  },
  {
    Code: "125PDMS131",
    ShortCode: "131",
    Type: "Primer",
    Dia: "125",
    Material: "Galvanize Çelik",
    Connection: "Vidalı",
    AppliedTo: "Oluk",
    MinFlow: "10 lt/sn",
    MaxFlow: "100 lt/sn",
    Description: "Galvaniz Oluklar",
  },
  {
    Code: "125ODMS132",
    ShortCode: "132",
    Type: "Taşkan",
    Dia: "125",
    Material: "Galvanize Çelik",
    Connection: "Vidalı",
    AppliedTo: "Oluk",
    MinFlow: "10 lt/sn",
    MaxFlow: "100 lt/sn",
    Description: "Galvaniz Oluklar",
  },
  {
    Code: "125PDMS001",
    ShortCode: "001",
    Type: "Primer",
    Dia: "125",
    Material: "Galvanize Çelik",
    Connection: "Bitümlü",
    AppliedTo: "Teras & Oluk",
    MinFlow: "10 lt/sn",
    MaxFlow: "100 lt/sn",
    Description: "Asfalt / Bitüm kaplamalı çatılar",
  },
  {
    Code: "125ODMS002",
    ShortCode: "002",
    Type: "Taşkan",
    Dia: "125",
    Material: "Galvanize Çelik",
    Connection: "Bitümlü",
    AppliedTo: "Teras & Oluk",
    MinFlow: "10 lt/sn",
    MaxFlow: "100 lt/sn",
    Description: "Asfalt / Bitüm kaplamalı çatılar",
  },
  {
    Code: "125PDMS011",
    ShortCode: "011",
    Type: "Primer",
    Dia: "125",
    Material: "Galvanize Çelik",
    Connection: "PVC",
    AppliedTo: "Teras & Oluk",
    MinFlow: "10 lt/sn",
    MaxFlow: "100 lt/sn",
    Description: "PVC membran su yalıtımlı çatı ve oluklar",
  },
  {
    Code: "125ODMS012",
    ShortCode: "012",
    Type: "Taşkan",
    Dia: "125",
    Material: "Galvanize Çelik",
    Connection: "PVC",
    AppliedTo: "Teras & Oluk",
    MinFlow: "10 lt/sn",
    MaxFlow: "100 lt/sn",
    Description: "PVC membran su yalıtımlı çatı ve oluklar",
  },
  {
    Code: "75ACBRMS033",
    ShortCode: "033",
    Type: "Kelepçe",
    Dia: "75",
    Material: "Galvanize Çelik",
    AppliedTo: "Oluk",
    Description: "İnce kesitli Oluklar (2mm'den ince oluklar için)",
  },
  {
    Code: "75ACBRSS034",
    ShortCode: "034",
    Type: "Kelepçe",
    Dia: "75",
    Material: "Ps. Çelik (304)",
    AppliedTo: "Oluk",
    Description: "İnce kesitli Oluklar (2mm'den ince oluklar için)",
  },
  {
    Code: "75OAMS035",
    ShortCode: "A35",
    Type: "Taşkan Körleme",
    Dia: "75",
    Material: "Galvanize Çelik",
    AppliedTo: "Teras",
    Description:
      "Teras çatılarda veya çok geniş oluklarda, normal süzgeçlerin alt 50mm yüksekliğini kapatarak taşkan kullanımını sağlayan ekonomik alternatif",
  },
  {
    Code: "75OASS037",
    ShortCode: "A37",
    Type: "Taşkan Körleme",
    Dia: "75",
    Material: "Ps. Çelik (316)",
    AppliedTo: "Teras",
    Description:
      "Teras çatılarda veya çok geniş oluklarda, normal süzgeçlerin alt 50mm yüksekliğini kapatarak taşkan kullanımını sağlayan ekonomik alternatif",
  },
  {
    Code: "75LGMS038",
    ShortCode: "A38",
    Type: "Yaprak Tutucu",
    Dia: "75",
    Material: "Galvanize Çelik",
    AppliedTo: "Teras & Oluk & Dış mekan",
    Description:
      "Yeşil çatılar veya açık alanlarda,normal süzgeçlerin alt 50mm yüksekliğini kapatarak taşkan kullanımını sağlayan ekonomik alternatif",
  },
  {
    Code: "75LGSS039",
    ShortCode: "A39",
    Type: "Yaprak Tutucu",
    Dia: "75",
    Material: "Ps. Çelik (304)",
    AppliedTo: "Teras & Oluk & Dış mekan",
    Description:
      "Yeşil çatılar veya açık alanlarda,normal süzgeçlerin alt 50mm yüksekliğini kapatarak taşkan kullanımını sağlayan ekonomik alternatif",
  },
  {
    Code: "75LGSS040",
    ShortCode: "A40",
    Type: "Yaprak Tutucu",
    Dia: "75",
    Material: "Ps. Çelik (316)",
    AppliedTo: "Teras & Oluk & Dış mekan",
    Description:
      "Yeşil çatılar veya açık alanlarda,normal süzgeçlerin alt 50mm yüksekliğini kapatarak taşkan kullanımını sağlayan ekonomik alternatif",
  },
  {
    Code: "75GGMS041",
    ShortCode: "A41",
    Type: "Çakıl Tutucu",
    Dia: "75",
    Material: "Galvanize Çelik",
    AppliedTo: "Teras & Oluk & Dış mekan",
    Description:
      "Çakıl kaplama teras çatılar veya açık alanlarda,normal süzgeçlerin alt 50mm yüksekliğini kapatarak taşkan kullanımını sağlayan ekonomik alternatif",
  },
  {
    Code: "75ACGGSS042",
    ShortCode: "A42",
    Type: "Çakıl Tutucu",
    Dia: "75",
    Material: "Ps. Çelik (304)",
    AppliedTo: "Teras & Oluk & Dış mekan",
    Description:
      "Çakıl kaplama teras çatılar veya açık alanlarda,normal süzgeçlerin alt 50mm yüksekliğini kapatarak taşkan kullanımını sağlayan ekonomik alternatif",
  },
  {
    Code: "75GGSS043",
    ShortCode: "A43",
    Type: "Çakıl Tutucu",
    Dia: "75",
    Material: "Ps. Çelik (316)",
    AppliedTo: "Teras & Oluk & Dış mekan",
    Description:
      "Çakıl kaplama teras çatılar veya açık alanlarda,normal süzgeçlerin alt 50mm yüksekliğini kapatarak taşkan kullanımını sağlayan ekonomik alternatif",
  },
  {
    Code: "125OAMS044",
    ShortCode: "A44",
    Type: "Taşkan Körleme",
    Dia: "125",
    Material: "Galvanize Çelik",
    AppliedTo: "Teras",
    Description:
      "Teras çatılarda veya çok geniş oluklarda, normal süzgeçlerin alt 50mm yüksekliğini kapatarak taşkan kullanımını sağlayan ekonomik alternatif",
  },
  {
    Code: "125OASS045",
    ShortCode: "A45",
    Type: "Taşkan Körleme",
    Dia: "125",
    Material: "Ps. Çelik (304)",
    AppliedTo: "Teras",
    Description:
      "Teras çatılarda veya çok geniş oluklarda, normal süzgeçlerin alt 50mm yüksekliğini kapatarak taşkan kullanımını sağlayan ekonomik alternatif",
  },
  {
    Code: "125OASS046",
    ShortCode: "A46",
    Type: "Taşkan Körleme",
    Dia: "125",
    Material: "Ps. Çelik (316)",
    AppliedTo: "Teras",
    Description:
      "Teras çatılarda veya çok geniş oluklarda, normal süzgeçlerin alt 50mm yüksekliğini kapatarak taşkan kullanımını sağlayan ekonomik alternatif",
  },
  {
    Code: "125LGMS047",
    ShortCode: "A47",
    Type: "Delikli Kapaklı Yaprak Tutucu",
    Dia: "125",
    Material: "Galvanize Çelik",
    AppliedTo: "Teras & Oluk & Dış mekan",
    Description:
      "Yeşil çatılar veya açık alanlarda,normal süzgeçlerin alt 50mm yüksekliğini kapatarak taşkan kullanımını sağlayan ekonomik alternatif",
  },
  {
    Code: "125LGSS048",
    ShortCode: "A48",
    Type: "Delikli Kapaklı Yaprak Tutucu",
    Dia: "125",
    Material: "Ps. Çelik (304)",
    AppliedTo: "Teras & Oluk & Dış mekan",
    Description:
      "Yeşil çatılar veya açık alanlarda,normal süzgeçlerin alt 50mm yüksekliğini kapatarak taşkan kullanımını sağlayan ekonomik alternatif",
  },
  {
    Code: "125LGSS049",
    ShortCode: "A49",
    Type: "Delikli Kapaklı Yaprak Tutucu",
    Dia: "125",
    Material: "Ps. Çelik (316)",
    AppliedTo: "Teras & Oluk & Dış mekan",
    Description:
      "Yeşil çatılar veya açık alanlarda,normal süzgeçlerin alt 50mm yüksekliğini kapatarak taşkan kullanımını sağlayan ekonomik alternatif",
  },
  {
    Code: "125GGMS050",
    ShortCode: "A50",
    Type: "Kapaksız Çakıl Tutucu",
    Dia: "125",
    Material: "Galvanize Çelik",
    AppliedTo: "Teras & Oluk & Dış mekan",
    Description:
      "Çakıl kaplama teras çatılar veya açık alanlarda,normal süzgeçlerin alt 50mm yüksekliğini kapatarak taşkan kullanımını sağlayan ekonomik alternatif",
  },
  {
    Code: "125GGSS051",
    ShortCode: "A51",
    Type: "Kapaksız Çakıl Tutucu",
    Dia: "125",
    Material: "Ps. Çelik (304)",
    AppliedTo: "Teras & Oluk & Dış mekan",
    Description:
      "Çakıl kaplama teras çatılar veya açık alanlarda,normal süzgeçlerin alt 50mm yüksekliğini kapatarak taşkan kullanımını sağlayan ekonomik alternatif",
  },
  {
    Code: "125GGSS052",
    ShortCode: "A52",
    Type: "Kapaksız Çakıl Tutucu",
    Dia: "125",
    Material: "Ps. Çelik (316)",
    AppliedTo: "Teras & Oluk & Dış mekan",
    Description:
      "Çakıl kaplama teras çatılar veya açık alanlarda,normal süzgeçlerin alt 50mm yüksekliğini kapatarak taşkan kullanımını sağlayan ekonomik alternatif",
  },
  {
    Code: "75ODGGSS053",
    ShortCode: "A53",
    Type: "Çakıl Tutuculu Taşkan Körleme",
    Dia: "75",
    Material: "Ps. Çelik (304)",
    AppliedTo: "Teras & Dış mekan",
    Description:
      "Çakıl kaplama teras çatılar veya açık alanlarda,normal süzgeçlerin alt 50mm yüksekliğini kapatarak taşkan kullanımını sağlayan ekonomik alternatif",
  },
  {
    Code: "75ODGGMS054",
    ShortCode: "A54",
    Type: "Çakıl Tutuculu Taşkan Körleme",
    Dia: "75",
    Material: "Galvanize Çelik",
    AppliedTo: "Teras & Dış mekan",
    Description:
      "Çakıl kaplama teras çatılar veya açık alanlarda,normal süzgeçlerin alt 50mm yüksekliğini kapatarak taşkan kullanımını sağlayan ekonomik alternatif",
  },
  {
    Code: "125ODGGSS055",
    ShortCode: "A55",
    Type: "Çakıl Tutuculu Taşkan Körleme",
    Dia: "125",
    Material: "Ps. Çelik (304)",
    AppliedTo: "Teras & Dış mekan",
    Description:
      "Çakıl kaplama teras çatılar veya açık alanlarda,normal süzgeçlerin alt 50mm yüksekliğini kapatarak taşkan kullanımını sağlayan ekonomik alternatif",
  },
  {
    Code: "125ODGGMS056",
    ShortCode: "A56",
    Type: "Çakıl Tutuculu Taşkan Körleme",
    Dia: "125",
    Material: "Galvanize Çelik",
    AppliedTo: "Teras & Dış mekan",
    Description:
      "Çakıl kaplama teras çatılar veya açık alanlarda,normal süzgeçlerin alt 50mm yüksekliğini kapatarak taşkan kullanımını sağlayan ekonomik alternatif",
  },
  {
    Code: "75ODLGSS057",
    ShortCode: "A57",
    Type: "Yaprak Tutuculu Taşkan Körleme",
    Dia: "75",
    Material: "Ps. Çelik (304)",
    AppliedTo: "Teras & Dış mekan",
    Description:
      "Yeşil çatılar veya açık alanlarda,normal süzgeçlerin alt 50mm yüksekliğini kapatarak taşkan kullanımını sağlayan ekonomik alternatif",
  },
  {
    Code: "75ODLGMS058",
    ShortCode: "A58",
    Type: "Yaprak Tutuculu Taşkan Körleme",
    Dia: "75",
    Material: "Galvanize Çelik",
    AppliedTo: "Teras & Dış mekan",
    Description:
      "Yeşil çatılar veya açık alanlarda,normal süzgeçlerin alt 50mm yüksekliğini kapatarak taşkan kullanımını sağlayan ekonomik alternatif",
  },
  {
    Code: "125ODLGSS059",
    ShortCode: "A59",
    Type: "Yaprak Tutuculu Taşkan Körleme",
    Dia: "125",
    Material: "Ps. Çelik (304)",
    AppliedTo: "Teras & Dış mekan",
    Description:
      "Yeşil çatılar veya açık alanlarda,normal süzgeçlerin alt 50mm yüksekliğini kapatarak taşkan kullanımını sağlayan ekonomik alternatif",
  },
  {
    Code: "125ODLGMS060",
    ShortCode: "A60",
    Type: "Yaprak Tutuculu Taşkan Körleme",
    Dia: "125",
    Material: "Galvanize Çelik",
    AppliedTo: "Teras & Dış mekan",
    Description:
      "Yeşil çatılar veya açık alanlarda,normal süzgeçlerin alt 50mm yüksekliğini kapatarak taşkan kullanımını sağlayan ekonomik alternatif",
  },
];

export default drainsdata;
